import { defineMessages } from 'react-intl';

import { CountryCodes } from 'src/common/enums';

export const defaultTitleAndDescription = {
  description:
    'Explore internships, graduate jobs & career development resources on Glints. ' +
    'Do what you love on our career discovery & development platform.',
  title: 'Glints - Career Discovery & Development Platform',
};

export const countrySpecificMetadata = defineMessages({
  [CountryCodes.ID]: {
    title: {
      id: 'id-landing-page_meta_title',
      defaultMessage: 'Glints | Indonesia Best Job Search & Hiring Marketplace',
    },
    description: {
      id: 'id-landing-page_meta_description',
      defaultMessage:
        'Glints is your place to find jobs and explore your career opportunities. Search ' +
        'for jobs online and grow your career with 5000+ companies hiring right now!',
    },
  },
  [CountryCodes.MY]: {
    title: {
      id: 'my-landing-page_meta_title',
      defaultMessage:
        'Glints | Jobs and Internships for Young Professionals in Malaysia',
    },
    description: {
      id: 'my-landing-page_meta_description',
      defaultMessage:
        'Explore internships, graduate jobs & career development resources in Malaysia on ' +
        'Glints. Do what you love on our career discovery & development platform.',
    },
  },
  [CountryCodes.SG]: {
    title: {
      id: 'sg-landing-page_meta_title',
      defaultMessage:
        'Glints | Jobs and Internships for Young Professionals in Singapore',
    },
    description: {
      id: 'sg-landing-page_meta_description',
      defaultMessage:
        'Discover Singapore careers, internships, graduate jobs & career advice on Glints, ' +
        'the #1 jobs marketplace for young professionals.',
    },
  },
  [CountryCodes.TW]: {
    title: {
      id: 'tw-landing-page_meta_title',
      defaultMessage:
        'Jobs, Career Advice, Recruitment Service | Glints Taiwan',
    },
    description: {
      id: 'tw-landing-page_meta_description',
      defaultMessage:
        '{time} popular jobs - Glints provides latest and high-quality job openings of Tech industry. You can find famous startups and enterprises on our job search platform. Glints also provides headhunting services for employers.',
    },
  },
  [CountryCodes.VN]: {
    title: {
      id: 'vn-landing-page_meta_title',
      defaultMessage: 'Glints | Vietnam Best Job Search & Hiring Marketplace',
    },
    description: {
      id: 'vn-landing-page_meta_description',
      defaultMessage: defaultTitleAndDescription.description,
    },
  },
});
