import React from 'react';
import moment from 'moment';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { CountryCodes } from 'src/common/enums';
import { useCurrentHref } from 'src/common/hooks/useUrl';
import { removeUndefinedOrNullValuesFromObject } from 'src/common/utils/objectUtils';
import { StringUtils } from 'src/common/utils/stringUtils';
import {
  countrySpecificMetadata,
  defaultTitleAndDescription,
} from 'src/components/MetaDescription/constants';
import { MetaRendererUtils } from 'src/components/MetaDescription/metaRendererUtils';
import {
  getCountry,
  getFacebookAppId,
} from 'src/modules/ClientConfig/Selectors';
import { CompanySchemaType } from 'src/modules/Company/types/companySchemaType';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  name?: Record<string, string>;
  property?: Record<string, string>;
  schema?: CompanySchemaType;
  canonical?: string;
  skipTwitterSite?: boolean;
  isLandingPage?: boolean;
};

const getTime = () => moment().format('MMMM YYYY');
const getMonthNumber = () => moment().format('M');
const getYear = () => moment().format('YYYY');

const siteNameSchema = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Glints',
  url: 'https://glints.com/',
};

/**
 * This component works as an adaptor for different metadata rendering library,
 * currently it renders to next/head
 */
export const MetadataRenderer: React.FC<React.PropsWithChildren<Props>> = ({
  title: _title,
  description: _description,
  image: _image,
  name: _name,
  property: _property,
  schema: _schema,
  canonical: _canonical,
  skipTwitterSite,
  isLandingPage,
}) => {
  const intl = useIntl();
  const url = useCurrentHref();
  const noTrailingSlashUrl = StringUtils.removeLastCharIf(url, (url) =>
    url.endsWith('/')
  );
  const facebookAppId = useSelector(getFacebookAppId);
  const country = useSelector(getCountry);

  const time = getTime();
  const monthNumber = getMonthNumber();
  const year = getYear();

  const titleAndDescription = countrySpecificMetadata[country]
    ? {
        title: intl.formatMessage(countrySpecificMetadata[country].title),
        description: intl.formatMessage(
          countrySpecificMetadata[country].description,
          {
            time,
            monthNumber,
            year,
          }
        ),
      }
    : defaultTitleAndDescription;
  const title = _title || titleAndDescription.title;
  const description = _description ?? titleAndDescription.description;
  const image = _image || '/images/logo.png';
  const name = _name ? _name : {};
  const property = {
    'fb:app_id': facebookAppId,
    'og:title': title,
    'og:site_name': country === CountryCodes.ID ? 'Glints Indonesia' : 'Glints',
    'og:description': description,
    'og:image': image,
    'og:image:width': '1200',
    'og:image:height': '630',
    'og:type': 'website',
    'og:url': url,
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image': image,
    'twitter:url': url,
    'twitter:card': 'summary_large_image',
    'twitter:site': '@glintsintern',
    ...(_property ? _property : {}),
  };
  if (!skipTwitterSite) {
    (property as { [key: string]: string })['twitter:site'] = '@glintsintern';
  }
  const canonical = _canonical ? _canonical : noTrailingSlashUrl;

  // Only show for global site
  const shouldShowSiteNameSchema = country === null;
  const xDefault = MetaRendererUtils.getAlternateDefault(canonical);
  const alternateEn = MetaRendererUtils.getAlternateEn(canonical, country);
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
        key="viewport"
      />
      <meta charSet="utf-8" />
      <link rel="canonical" href={canonical} />
      <If condition={!isLandingPage}>
        <link rel="alternate" hrefLang="x-default" href={xDefault} />
        <If condition={country === CountryCodes.ID}>
          <link rel="alternate" hrefLang="id-id" href={xDefault} />
          <link rel="alternate" hrefLang="en-id" href={alternateEn} />
        </If>
        <If condition={country === CountryCodes.VN}>
          <link rel="alternate" hrefLang="vi-vn" href={xDefault} />
          <link rel="alternate" hrefLang="en-vn" href={alternateEn} />
        </If>
      </If>

      {Object.entries(name).map(([key, value]) => (
        <meta name={key} content={value} key={key} />
      ))}
      {Object.entries(property).map(([key, value]) => (
        <meta property={key} content={value} key={key} />
      ))}
      <If condition={shouldShowSiteNameSchema}>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(siteNameSchema)}`,
          }}
        />
      </If>
      <If condition={_schema}>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(
              removeUndefinedOrNullValuesFromObject(_schema)
            )}`,
          }}
        />
      </If>
    </Head>
  );
};
