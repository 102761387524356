import { useRouter } from 'next/router';

import useConfig from 'src/common/hooks/useConfig';

export const constructHrefWithoutSearchAndHash = ({
  base,
  asPath,
  hash,
  encode,
}: {
  base: string;
  asPath: string;
  hash?: string;
  encode?: boolean;
}): string => {
  const urlString = asPath ? base + asPath : base;
  const url = new URL(urlString);
  url.hash = '';
  url.search = '';
  if (hash) url.hash = hash;
  return encode ? encodeURIComponent(url.href) : url.href;
};

export const useCurrentHrefWithoutSearchAndHash = (
  { hash, encode }: { hash?: string; encode?: boolean } = { encode: true }
) => {
  const router = useRouter();
  const config = useConfig();
  return constructHrefWithoutSearchAndHash({
    base: config.CANDIDATE_BASE,
    asPath: router.asPath,
    hash,
    encode,
  });
};

export const useCurrentHref = () => {
  const router = useRouter();
  const config = useConfig();
  return config.CANDIDATE_BASE + router.asPath;
};

export const useHostNameAndBaseName = () => {
  const config = useConfig();
  const baseName = config.BASENAME === '/' ? '' : config.BASENAME;
  return config.CANDIDATE_BASE + baseName;
};
