import { CountryCodes } from 'src/common/enums';

export class MetaRendererUtils {
  static getAlternateDefault = (canonical: string): string => {
    return canonical.replace('/en', '');
  };

  static getAlternateEn = (
    canonical: string,
    country?: CountryCodes
  ): string => {
    if (!country) return canonical;
    const lowerCaseCountry = country.toLowerCase();
    return canonical.includes('/en')
      ? canonical
      : canonical.replace(`/${lowerCaseCountry}`, `/${lowerCaseCountry}/en`);
  };
}
