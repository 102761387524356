// Remove undefined, null, empty string. https://stackoverflow.com/a/38340374
export const removeUndefinedOrNullValuesFromObject = (obj: any): any => {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) {
      newObj[key] = removeUndefinedOrNullValuesFromObject(obj[key]);
    } else if (obj[key] || obj[key] === 0) newObj[key] = obj[key];
  });
  return newObj;
};
